import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styles from './style.module.scss';
import dynamic from 'next/dynamic';
const RequestModal = dynamic(() => import('./requestModal'));
interface RequestCallProps {
  // data: React.ReactNode[];
}
const RequestCall: React.FC<RequestCallProps> = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModal = (e?: any) => {
    e?.preventDefault();
    setIsModalOpen(!isModalOpen);
  };
  return (
    <>
      <Link
        href={'tel:+91-9314444747'}
        className={styles['request-call-mobile']}
      >
        <span className={styles['phone-icon']}>
          <Image
            src="/images/callon.gif"
            quality={100}
            height={28}
            width={28}
            alt="furniture"
            unoptimized
          />
        </span>
        <p>
          Buy On <span>Phone</span>
        </p>
      </Link>
      <Link href={'#'} className={styles['request-call']} onClick={handleModal}>
        <Image
          src="/images/req-call.png"
          quality={100}
          height={80}
          width={65}
          alt="furniture"
          className={styles['request-call-img']}
        />
      </Link>
      {isModalOpen && <RequestModal onClose={handleModal} />}
    </>
  );
};

export default RequestCall;
